/* eslint-disable max-len */
interface Photo {
  url: string;
  width: number;
}

interface Tags {
  tag: string;
}

interface Parceiros {
  tipo?: string;
  parceiro?: string;
  link?: string;
}

interface Data {
  logo: string;
  logoWidth: string;
  capa: string;
  title: string;
  text: string;
  photos: Photo[];
  tags: Tags[];
  parceiros?: Parceiros[];
}

const DataMarisa: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/marisa/Logo_2x.webp',
  logoWidth: '166px',
  capa: 'https://somocollab.com/PORTFOLIOS/marisa/CAPA_2x.webp',
  title: 'Lojas Marisa',
  text: 'Somar com Marisa significa ir além: fazer parte da construção de um Brasil mais justo para todas, todes e todos. Nessa parceria, que já dura três anos, somamos com a comunicação interna da empresa, com o treinamento e o engajamento das pessoas que constroem o Jeito Rosa de Ser da Marisa.',
  tags: [
    { tag: 'ENDOMARKETING' },
    { tag: 'APRESENTAÇÕES' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/marisa/01_2x.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/marisa/02_2x.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/marisa/03_2x.webp',
      width: 757,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/marisa/04_2x.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/marisa/05_2x.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/marisa/06_2x.webp',
      width: 1017,
    },
  ],
};

const DataDafiti: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Dafiti/Logo.webp',
  logoWidth: '166px',
  capa: 'https://somocollab.com/PORTFOLIOS/Dafiti/CAPA.webp',
  title: 'Grupo Dafiti',
  text: 'A Dafiti é conhecida por ser um dos maiores e-commerces de moda do Brasil. Para somarmos com a marca, oferecemos nossos serviços de foto e vídeo, comunicando as principais tendências e a versatilidade de peças que ela oferece. ',
  tags: [
    { tag: 'FOTO E VÍDEO' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Dafiti/02.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Dafiti/03.webp',
      width: 1017,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Dafiti/04.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Dafiti/06.webp',
      width: 749,
    },
  ],
};

const DataDevyx: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Devyx/Logo.webp',
  logoWidth: '166px',
  capa: 'https://somocollab.com/PORTFOLIOS/Devyx/CAPA.webp',
  title: 'Devyx',
  text: 'A Devyx se destaca no mercado de tecnologia com ferramentas de criação de landing pages, plataforma de disparo de SMS e outras soluções. E, para aumentar sua conversão e apresentação de produtos, nossos parceiros de longa data contam com as soluções da Somo para criar seus sites, landing pages e vídeos.',
  tags: [
    { tag: 'UX/UI' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Devyx/01.webp',
      width: 1013,
    },
    {
      url: 'https://assets7.lottiefiles.com/packages/lf20_QzppQwMzpf.json',
      width: 598,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Devyx/03.webp',
      width: 597,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Devyx/04.webp',
      width: 745,
    },
  ],
};

const DataEyou: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Eyou/logo.webp',
  logoWidth: '178px',
  capa: 'https://somocollab.com/PORTFOLIOS/Eyou/CAPA.webp',
  title: 'Eyou',
  text: 'A Eyou é conhecida por facilitar a comunicação entre empresas e clientes por meio de soluções inteligentes. Para somar com esse trabalho, aplicamos nossa expertise em UX - User Experience - e rebranding, tornando a plataforma mais intuitiva, fácil de trabalhar e próxima do usuário.',
  tags: [
    { tag: 'UX/UI' },
    { tag: 'BRANDING' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Eyou/01.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Eyou/02.webp',
      width: 757,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Eyou/03.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Eyou/04.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Eyou/05.webp',
      width: 748,
    },
  ],
};

const DataGood: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Good/Logo.webp',
  logoWidth: '178px',
  capa: 'https://somocollab.com/PORTFOLIOS/Good/CAPA.webp',
  title: 'GoodStorage',
  text: 'Espaços inteligentes são essenciais para dinamizar as grandes cidades. Diante disso, desde 2020 temos somado nossa expertise com a da Goodstorage para criar uma comunicação mais próxima e consciente, incentivando o uso de self storages para uma rotina mais sustentável - dentro e fora dos escritórios.',
  tags: [
    { tag: 'ENDOMARKETING' },
    { tag: 'APRESENTAÇÕES' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Good/01.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Good/02.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Good/03.webp',
      width: 757,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Good/04.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Good/05.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Good/06.webp',
      width: 1017,
    },
  ],
};

const DataLello: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Lello/Logo.webp',
  logoWidth: '124px',
  capa: 'https://somocollab.com/PORTFOLIOS/Lello/CAPA.webp',
  title: 'Lello Imóveis',
  text: 'A Lello Imóveis é uma das empresas mais conhecidas do mercado imobiliário paulista. Nessa parceria, somamos nossa expertise e desenvolvemos um aplicativo que facilita o dia a dia dos corretores e, consequentemente, a vida de quem busca por o melhor lugar para viver.',
  tags: [
    { tag: 'UX/UI' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Lello/01.webp',
      width: 748,
    },
    {
      url: 'https://assets5.lottiefiles.com/packages/lf20_UbmIOP4Aqd.json',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Lello/03.webp',
      width: 1017,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Lello/04.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Lello/05.webp',
      width: 757,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Lello/06.webp',
      width: 748,
    },
  ],
};

const DataMonteBravo: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/MonteBravo/Logo.webp',
  logoWidth: '238px',
  capa: 'https://somocollab.com/PORTFOLIOS/MonteBravo/CAPA.webp',
  title: 'Monte Bravo',
  text: 'Como melhor empresa de assessoria de investimentos credenciada à XP, a Monte Bravo Investimentos conta, ano após ano, com as soluções da Somo para a criação de hotsites, apresentações institucionais e materiais internos e externos, que traduzem a solidez da marca.',
  tags: [
    { tag: 'ENDOMARKETING' },
    { tag: 'APRESENTAÇÕES' },
    { tag: 'UX/UI' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/MonteBravo/01.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/MonteBravo/02.webp',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/MonteBravo/03.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/MonteBravo/04.webp',
      width: 599,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/MonteBravo/05.webp',
      width: 1017,
    },
    // {
    //   url: 'https://somocollab.com/PORTFOLIOS/MonteBravo/06.webp',
    //   width: 748,
    // },
  ],
};

const DataSuntuo: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Suntuo/Logo.webp',
  logoWidth: '210px',
  capa: 'https://somocollab.com/PORTFOLIOS/Suntuo/CAPA.webp',
  title: 'Suntuo',
  text: 'Somamos para desenvolver o branding e traduzir os valores e sensações que a Suntuo Arquitetura oferece enquanto marca.  A comunicação foi inspirada na leveza da vida litorânea e na realização de abrir as janelas e poder contemplar o mar.',
  tags: [
    { tag: 'BRANDING' },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Suntuo/01.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Suntuo/02.webp',
      width: 757,
    },
    {
      url: 'https://assets5.lottiefiles.com/packages/lf20_lJxh9DP0QE.json',
      width: 476,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Suntuo/04.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Suntuo/05.webp',
      width: 748,
    },
  ],
};

const DataTige: Data = {
  logo: 'https://somocollab.com/PORTFOLIOS/Tige/Logo.webp',
  logoWidth: '212px',
  capa: 'https://somocollab.com/PORTFOLIOS/Tige/CAPA.webp',
  title: 'Tigé Boats',
  text: 'Navegamos por águas internacionais e tivemos a honra de levar o Tigé Boats dos Estados Unidos para a Europa. O novo site, desenvolvido pela SOMO, foi idealizado para mostrar a potência das lanças Tigé e mostrar que um barco pode ir muito além do que se imagina.',
  tags: [
    { tag: 'UX/UI' },
  ],
  parceiros: [
    {
      tipo: 'Parceria/Programação:',
      parceiro: 'JR AGENCY',
      link: 'https://jr.agency',
    },
  ],
  photos: [
    {
      url: 'https://somocollab.com/PORTFOLIOS/Tige/01.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Tige/02.webp',
      width: 757,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Tige/03.webp',
      width: 748,
    },
    {
      url: 'https://somocollab.com/PORTFOLIOS/Tige/04.webp',
      width: 748,
    },
  ],
};
export {
  DataMarisa, DataDafiti, DataDevyx, DataEyou, DataGood, DataLello, DataSuntuo, DataMonteBravo, DataTige,
};
