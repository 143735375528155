/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import { Container } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';
import { useEffect, useRef, useState } from 'react';
import { useColorBackground } from 'Contexts/BannerCollor';
import * as S from './styles';
import LogoMini from '../../assets/images/logo-mini.svg';
import Logo from '../../assets/images/logo.svg';
import LogoMiniBlack from '../../assets/images/logo-mini-black.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/icons/linkedin.svg';
import { ReactComponent as Instagram } from '../../assets/images/icons/instagram.svg';
import { ReactComponent as Behance } from '../../assets/images/icons/behance.svg';
import Button from '../common/Button';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [offMainPage, setOffMainPage] = useState(false);
  const { colorBackground } = useColorBackground();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const htmlTag = document.querySelector('html');
    if (isOpen) {
      htmlTag.classList.add('overflow-hidden');
    } else {
      htmlTag.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const divRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuFixed, setMenuFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      if (scrollPosition > 88 && screenWidth > 700) {
        divRef.current?.classList.add('fixed-menu');
        setMenuFixed(true);
      } else {
        divRef.current?.classList.remove('fixed-menu');
        setMenuFixed(false);
      }

      if (scrollPosition > 66 && screenWidth < 700) {
        divRef.current?.classList.add('fixed-menu-mobile');
      } else {
        divRef.current?.classList.remove('fixed-menu-mobile');
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (window.location.pathname !== '/') {
      divRef.current?.classList.add('fixed-menu');
      setMenuFixed(true);
      setOffMainPage(true);
    }
  }, []);

  return (
    <>
      <S.BurguerMenu id="header">
        <Menu isOpen={isOpen} onClose={toggleMenu} right className="right">
          <S.HeaderMenuMobal>
            <div className="logo-container">
              <a href="/#header"><img src={LogoMiniBlack} alt="Logo SomoCollab" /></a>
            </div>
            <div className="language-container">
              <S.LanguageBox className="black">
                PT
              </S.LanguageBox>
            </div>
          </S.HeaderMenuMobal>
          <a className="font-antonio link" href="/" onClick={toggleMenu}>Home</a>
          <a className="font-antonio link" href="/#cases" onClick={toggleMenu}>Cases</a>
          <a className="font-antonio link" href="/#somofaz" onClick={toggleMenu}>Serviços</a>
          <a className="font-antonio link" href="/#quemSomo" onClick={toggleMenu}>Quem SOMO</a>
          <a className="font-antonio link" href="#footer" onClick={toggleMenu}>Contato</a>
        </Menu>
      </S.BurguerMenu>
      <S.Header ref={divRef} className={`${offMainPage ? 'simple-menu' : ''}`}>
        <Container className=" d-flex align-items-center justify-content-between h-100 fixed-menu ">
          <S.LeftMenu>
            <S.LogoContainer className={`${menuFixed ? 'fixed-menu' : ''} logo-container`}>
              {offMainPage ? (
                <a href="/#header"><img src={LogoMiniBlack} width={37} className="logo-mini" alt="Logo SomoCollab" /></a>
              ) : (
                <>
                  <S.BgLogo className={`${menuFixed ? 'fixed-menu' : ''} ${colorBackground}`}>
                    <a href="/#header"><img src={Logo} className="logo-big" alt="Logo SomoCollab" /></a>
                  </S.BgLogo>
                  <a href="/#header"><img src={LogoMini} className="logo-mini" alt="Logo SomoCollab" /></a>
                </>
              )}
            </S.LogoContainer>
          </S.LeftMenu>
          <S.RightMenu className={`${menuFixed ? 'fixed-menu' : ''} ${offMainPage ? 'simple-menu' : ''} d-flex align-items-center`}>
            <div className="d-none d-lg-flex">
              <a href="/#cases">
                Cases
              </a>
              <a href="/#somofaz">
                Serviços
              </a>
              <a href="/#quemSomo" className="width-ajst">
                Quem Somo
              </a>
              <Button variant={menuFixed ? 'red' : 'blackFull'} href="/#footer" placeholder="Contato" />
            </div>
            <div className="d-flex">
              <div className="links">
                <a href="https://www.linkedin.com/company/somocollab/" target="_blank" className="fill" rel="noreferrer noopener">
                  <LinkedIn />
                </a>
                <a href="https://www.instagram.com/somocollab_oficial/" target="_blank" className="fill" rel="noreferrer noopener">
                  <Instagram />
                </a>
                <a href="https://www.behance.net/somocollab" target="_blank" className="stroke" rel="noreferrer noopener">
                  <Behance />
                </a>
              </div>
              <S.LanguageBox className={`${offMainPage ? 'simple-menu' : ''}`}>
                PT
              </S.LanguageBox>
            </div>
            <S.BoxMenuMobile className="d-flex d-lg-none">
              <button className="button-menu" onClick={() => toggleMenu()}>
                <span className="bars" />
                <span className="bars" />
                <span className="bars" />
              </button>
            </S.BoxMenuMobile>
          </S.RightMenu>
        </Container>
      </S.Header>
    </>
  );
}
