import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Cases from './pages/Cases/index';
import Marisa from './pages/Marisa/index';
import Dafiti from './pages/Dafiti/index';
import Devyx from './pages/Devyx';
import Eyou from './pages/Eyou';
import GoodStorage from './pages/GoodStorage';
import Lello from './pages/Lello';
import Suntuo from './pages/Suntuo';
import MonteBravo from './pages/MonteBravo';
import Tige from './pages/Tige';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cases" element={<Cases />} />
      <Route path="/cases/marisa" element={<Marisa />} />
      <Route path="/cases/dafiti" element={<Dafiti />} />
      <Route path="/cases/devyx" element={<Devyx />} />
      <Route path="/cases/eyou" element={<Eyou />} />
      <Route path="/cases/goodstorage" element={<GoodStorage />} />
      <Route path="/cases/lello" element={<Lello />} />
      <Route path="/cases/suntuo" element={<Suntuo />} />
      <Route path="/cases/monte-bravo" element={<MonteBravo />} />
      <Route path="/cases/tige" element={<Tige />} />
    </Routes>
  );
}
