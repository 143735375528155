/* eslint-disable react/jsx-props-no-spreading */
import Slider from 'react-slick';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as S from './styles';

import Dafiti from '../../../assets/images/customers/dafiti/logo-white.svg';
import Galapagos from '../../../assets/images/customers/galapagos/logo-white.svg';
import GoodStorage from '../../../assets/images/customers/goodstorage/logo-white.svg';
import JardimSul from '../../../assets/images/customers/jardimsul/logo-white.svg';
import Lello from '../../../assets/images/customers/lello/logo-white.svg';
import Marisa from '../../../assets/images/customers/marisa/logo-white.svg';
import MonteBravo from '../../../assets/images/customers/montebravo/logo-white.svg';
import RoofTop from '../../../assets/images/customers/rooftop/logo-white.svg';
import Suntuo from '../../../assets/images/customers/suntuo/logo-white.svg';
import EffectSomo from './EffectSomo';

export default function Section2() {
  const settings = {
    infinite: true,
    slidesToScroll: 3,
    autoplay: true,
    speed: 20000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    pauseOnHover: true,
    focusOnSelect: true,
    swipeToSlide: true,
    dots: false,
    arrows: false,
    className: 'slider variable-width',
    variableWidth: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="section2">
      <S.SliderStyle>
        <Slider {...settings}>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={Dafiti} alt="Dafiti" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={Galapagos} alt="Galapagos" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={GoodStorage} alt="GoodStorage" className="mb-n6" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={JardimSul} alt="JardimSul" className="mt-n1" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={Lello} alt="Lello" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={Marisa} alt="Marisa" className="mb-n5" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={MonteBravo} alt="MonteBravo" className="mb-n5" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={RoofTop} alt="RoofTop" className="mb-n5" />
          </div>
          <div className="pr-5 my-5 d-flex align-items-center justify-content-center logos">
            <img src={Suntuo} alt="Suntuo" />
          </div>
        </Slider>
      </S.SliderStyle>
      <EffectSomo />
    </div>
  );
}
