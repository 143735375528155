import styled from 'styled-components';
import plusWhite from '../../../assets/images/home/somofaz/plus-white.png';
import plusRed from '../../../assets/images/home/somofaz/plus-red.png';
import Circle from '../../../assets/images/home/somofaz/circle.png';

export const SomoFaz = styled.div`
 position: relative;
 .plus-white{
   position: absolute;
   bottom: -300px;
   left: 150px;
   &:after{
    content: "";
    background-image: url(${plusWhite});
    width: 62px;
    height: 62px;
    display: block;
  }
 }

  .circle{
    position: absolute;
    bottom: -200px;
    right: -60px;
    &:after{
        content: "";
        background-image: url(${Circle});
        width: 340px;
        height: 340px;
        z-index: 1;
        display: block;
        animation: girar 20s linear infinite;

      }
  }

 .plus-red{
   position: absolute;
   bottom: -200px;
   right: 130px;
   z-index: 1;
  &:after{
      content: "";
      background-image: url(${plusRed});
      width: 52px;
      height: 52px;
      z-index: 1;
      display: block;
      position: relative;
    }
 }

 @keyframes girar {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

export const BackGround = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  h4{
    position: relative;
    z-index: 3;
    font-weight: 700;
    font-size: 88px;
    line-height: 110%;
    margin-bottom: 14px;
  }
  span{
    letter-spacing: 0.335em;
    text-transform: uppercase;
    color: #FABA36;
    position: relative;
    z-index: 3;
  }
`;

export const BackGroundMobile = styled.div`
  h4{
    position: relative;
    z-index: 3;
    font-weight: 700;
    font-size: 56px !important;
    line-height: 110%;
    margin-bottom: 14px;
      margin-top: 60px;
  }
  span{
    text-transform: uppercase;
    color: #FABA36;
    position: relative;
    z-index: 3;
    letter-spacing: 0.335em;
    font-size: 20px;
  }
`;

export const ContentFixed = styled.div`
`;

export const Card = styled.div`
  width: 302px;
  height: 426px;
  left: calc(50% - 302px/2);
  top: 3131px;
  background: rgba(30, 30, 30, 0.95);
  border: 2px solid #E8435D;
  border-radius: 16px;
  padding-left: 48px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  z-index: 4;
  @media (max-width: 700px) {
      height: auto;
      padding: 32px 23px 32px 32px;
      span{
        font-size: 24px;
        font-weight: bold;
      }
    }
  img,span{
    margin-bottom: 16px;
  }
  &.card1{
    margin-top: 100px;
    @media (max-width: 700px) {
      margin-top: 60px;
    }
  }
  @media (max-width: 700px) {
    &.card1, &.card3, &.card5{
      margin-left: 0;
      transition: 1s;
      &.active{
        margin-left: calc(100% - 302px);
        transition: 1s;
      }
    }
    &.card2, &.card4, &.card6{
      margin-left: calc(100% - 302px);
      transition: 1s;
      &.active{
        margin-left: 0;
        transition: 1s;
      }
    }
  }
`;
