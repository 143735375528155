import styled from 'styled-components';
import star from '../../assets/images/icons/start.svg';

export const Container = styled.div`
.gallery-item {
  z-index: 1;
  height: 476px;
  margin-right: 56px;
  .gallery-item-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    will-change: transform;
    /* clip-path: inset(0% 100% 0% 0%); */
    /* transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1); */
    transform: rotate(8deg) scale(0.6, 0.6);
    transition: 1s;
    &.reveal {
      transform: translate(0px, 0px);
      transition: 1s;

      /* clip-path: inset(0% 0% 0% 0%); */
      /* .gallery__item-imginner {
        transform: scale(1);
      } */
    }
  }
  .gallery__item-imginner {
    background-size: cover;
    background-position: center;
    transform-origin: center left;
    width: 100%;
    height: 100%;
    /* will-change: transform; */
    /* transform: scale(1.1); */
    /* transition: transform 2s cubic-bezier(0.77, 0, 0.175, 1); */
  }
}

.main-container {
  padding-right: 500px;
  box-sizing: border-box;
}

/* .c-fixed_target {
  position: absolute;
  top: -100vh;
  right: 0;
  left: 0;
  bottom: -100vh;
} */
.scroll-container {
  margin-right: 500px;
}

.content {
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  align-items: center;
}

.gallery {
  display: flex;
  justify-content: flex-start;
  /* grid-template-columns: repeat(50, 2%); */
  /* grid-template-rows: repeat(50, 2%); */
  /* width: 469vw; */
  /* padding-left: 10vw; */
  height: 100%;
  position: relative;
  align-items: center;
  background-color: white;
}
.gallery-helper {
  grid-area: 43/1 / span 2 / span 1;
  font-size: 20px;
}
.behind-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12em;
  /* transform: translate(-50%, -50%); */
  text-transform: uppercase;
  font-weight: 500;

  letter-spacing: 50px;
  &.fill {
    color: rgba(0, 0, 0, 0.2);
  }
  &.outline {
    z-index: 2;
    mix-blend-mode: overlay;
  }
}


`;

export const FirstSection = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding-top: 87px;
  .left-side{
    width: 50%;
    background-size: cover;
  }
  .right-side{
    width: 50%;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding:0 90px;
    white-space: normal !important;
    position: relative;
    h1{
      font-family: 'Antonio';
      font-weight: 700;
      font-size: 88px;
      line-height: 110%;
      color: #E8435D;
      margin-bottom: 45px;
    }
    p{
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
    }
    .tags{
      &.nohave-partner{
        margin-bottom: 70px;
      }
      &.have-partner{
        margin-bottom: 10px;
      }
      margin-bottom: 70px;
      > div{
        background: #F3F3F3;
        border-radius: 24px;
        padding: 5px 10px 10px 40px;
        margin-bottom: 10px;
        width: fit-content;
        &:last-child{
          margin-bottom: 0;
        }
        span{
          font-family: 'Antonio';
          font-size: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.2em;
          color: #000000;
          position: relative;
          &:after{
            content: "";
            width: 16px;
            height: 1px;
            background-color: #FABA36;
            position: absolute;
            left: -25px;
            top: 11px;
          }
        }
      }
    }

    .parceiros{
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      > a{
        display: inline-block;
        background: #1E1E1E;
        border-radius: 24px;
        padding: 5px 15px 10px 40px;
        margin-bottom: 10px;
        width: fit-content;
        transition: 0.5s;
        &:last-child{
          margin-bottom: 0;
        }
        span{
          font-family: 'Antonio';
          font-size: 16px;
          display: flex;
          align-items: center;
          color: #fff;
          position: relative;
          &:after{
            content: "";
            background: url(${star});
            width: 16px;
            height: 16px;
            position: absolute;
            left: -25px;
            top: 3px;
          }
        }
        &:hover{
          background: #FABA36;
          text-decoration: none;
          transition: 0.5s;
        }
      }
    }
    .animation{
      position: absolute;
      bottom: 90px;
      right: 100px;
      width: 120px;
    }
    .selo{
      position: absolute;
      bottom: 90px;
      left: -100px;
      transform: rotate(33.66deg);
      img{
        width: 167px;
      }
    }
  }
`;

export const MoreCases = styled.div`
  min-width: 550px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 56px;
  a{
    font-family: 'Antonio';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 110%;
    color: #000000;
    position: relative;
    transition:color 0.3s;
    &:after{
      content: '';
      width: 100%;
      height: 4px;
      background: rgba(0, 0, 0, 1);
      position: absolute;
      bottom: -20px;
      left: 0;
    }
    &:before{
      content: '';
      width: 0;
      height: 4px;
      background: ${({ theme }) => theme.colors.primary.main};
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 5;
      transition:color 0.3s;
      transition-delay: 0.7s;
    }
    &:hover{
      color: ${({ theme }) => theme.colors.primary.main};
      transition:color 0.3s;
      transition-delay: 0.5s;
      text-decoration: none;
      &:before{
        width: 100%;
        transition: 1s;
      }
    }
  }
`;

export const LastSection = styled.div`
  background-color: #EEEEEE;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-left: 200px;
  position: relative;
  .selo{
    position: absolute;
    bottom: -100px;
    left: -100px;
    transform: rotate(-30deg);
    img{
      width: 331.21px;
    }
  }
  .animation{
    width: 600px;
    position: absolute;
    right: -20px;
    bottom: 50px;
  }
  .first-line{
    h3, strong{
      font-family: 'Antonio';
      font-style: normal;
      font-weight: 300;
      font-size: 81.2903px;
      line-height: 110%;
      color: #1E1E1E;
    }
    strong{
      font-weight: bold;
    }
  }
  .second-line{
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    .mail-links{
      .mail{
        color: #1E1E1E;
        transition: 0.3s;
        text-decoration: underline;
        &:hover{
          color: ${({ theme }) => theme.colors.yellow};
          transition: 0.3s;
        }
      }
      .links{
        .fill{
          svg{
            path{
              fill: #1E1E1E;
              transition: 0.3s;
            }
          }
          &:hover{
            svg{
              path{
              fill: ${({ theme }) => theme.colors.yellow};
              transition: 0.3s;
            }
          }
          }
        }
        .stroke{
          svg{
            path{
              stroke: #1E1E1E;
              transition: 0.3s;
            }
          }
          &:hover{
            svg{
            path{
            stroke: ${({ theme }) => theme.colors.yellow};
            transition: 0.3s;
          }
          }
          }
        }
      }
    }
  }
`;
