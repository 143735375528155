/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-new */

import React, { useEffect, useRef } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { Player } from '@lottiefiles/react-lottie-player';
import Button from 'components/common/Button';
import { Col, Row } from 'react-bootstrap';
import * as S from './styles';
import GalleryItem from './image';
import Selo from '../../assets/images/selo.webp';
import Animation from '../../assets/images/portfolio/Animation.json';
import { ReactComponent as Instagram } from '../../assets/images/icons/instagram.svg';
import { ReactComponent as Behance } from '../../assets/images/icons/behance.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/icons/linkedin.svg';
import AnimationLast from '../../assets/images/footer/animation.json';

export default function Marisa({ data }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      if (typeof window === 'undefined' || !window.document) {
        return;
      }
      const scroll = new LocomotiveScroll({
        el: ref.current,
        smooth: true,
        direction: 'horizontal',
        multiplier: 0.5,
      });
    }
  }, []);

  const images = data.photos.map(({ url, width }) => (
    <GalleryItem
      key={url}
      src={url}
      width={width}
    // index={elementIndex}
    />
  ));
  if (typeof window === 'undefined' || !window.document) {
    return null;
  }

  return (
    <S.Container>
      <div className="main-container">
        <div className="scroll-container" data-scroll-container ref={ref}>
          <div className="content">
            <div className="gallery">
              <S.FirstSection data-scroll>
                <div
                  className="left-side"
                  style={{ backgroundImage: `url(${data.capa})` }}
                />
                <div className="right-side">
                  <img src={data.logo} className="logo mt-5" width={data.logoWidth} alt="" />
                  <div>
                    <h1>
                      {data.title}
                    </h1>
                    <p>{data.text}</p>
                  </div>
                  <div>
                    <div className={`tags ${data.parceiros ? 'have-partner' : 'nohave-partner'}`}>
                      {data.tags.map(({ tag }) => (
                        <div>
                          <span>{tag}</span>
                        </div>
                      ))}
                    </div>
                    {data.parceiros && (
                      <div className="parceiros">
                        {data.parceiros.map(({ tipo, parceiro, link }) => (
                          <a href={link} target="_blank" rel="noreferrer">
                            <span>
                              {tipo}
                              {' '}
                              {parceiro}
                            </span>
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="selo">
                    <img src={Selo} />
                  </div>

                  <div className="animation">
                    <Player
                      autoplay
                      loop
                      src={Animation}
                    />
                  </div>
                </div>
              </S.FirstSection>
              {images}
              <S.MoreCases data-scroll>
                <a href="/cases">
                  mais cases
                </a>
              </S.MoreCases>
              <S.LastSection data-scroll>
                <div className="first-line">
                  <h3>
                    Some ideias.
                    {' '}
                    <strong>Converse com a Somo</strong>
                  </h3>
                </div>
                <div className="second-line w-100">
                  <Row className="w-100">
                    <Col lg={4}>
                      <Button href="https://wa.me/message/UQRIG6V72FMAC1" placeholder="Whatsapp" variant="whatsapp-porfilio" />
                    </Col>
                    <Col lg={4}>
                      <Button
                        href="https://somocollab.com/DOCS/Apresentacao-Institucional-Somo-2023.pdf"
                        placeholder="Apresentação"
                        variant="apresentacao"
                      />

                    </Col>
                    <Col lg={4}>
                      <div className="d-flex justify-content-between flex-column  align-items-start flex-column mail-links">
                        <a href="mailto:contato@somocollab.com" className="mail mb-3 ">contato@somocollab.com</a>
                        <div className="links d-flex align-items-center justify-content-start w-100">
                          <a href="https://www.linkedin.com/company/somocollab/" target="_blank" className="fill mr-3" rel="noreferrer noopener">
                            <LinkedIn />
                          </a>
                          <a href="https://www.instagram.com/somocollab_oficial/" target="_blank" className="fill mr-3" rel="noreferrer noopener">
                            <Instagram />
                          </a>
                          <a href="https://www.behance.net/somocollab" target="_blank" className="stroke" rel="noreferrer noopener">
                            <Behance />
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="selo">
                  <img src={Selo} />
                </div>
                <Player
                  className="animation"
                  autoplay
                  loop
                  src={AnimationLast}
                />
              </S.LastSection>
            </div>
          </div>
        </div>
      </div>
    </S.Container>

  );
}
