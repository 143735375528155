import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'basic-sans', sans-serif;
        font-size: ${({ theme }) => theme.fonts[5]};
        color: ${({ theme }) => theme.colors.white};
      }
      html{
      scroll-behavior: smooth;
      scroll-padding: 100px;
    }
    body{
        background: ${({ theme }) => theme.colors.background};
        font-size: 16px;
        overflow-x: hidden;
    }
  ::-webkit-scrollbar {
    position: fixed;
    width: 5px;
    height: 0;
  }

  /* Ajusta a posição do scroll */
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* Define a posição do scroll */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  /* Define a posição do scroll */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* Define fundo do scroll */
  ::-webkit-scrollbar-track {
    background-color:rgba(158, 158, 158, 0.54);
  }
  button{
      cursor: pointer;
  }
  .font-antonio{
    font-family: 'Antonio', sans-serif;
  }

  &.bgred{
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
  &.bgpurple{
    background-color: ${({ theme }) => theme.colors.purple};
  }
  &.bgblack{
    background-color: ${({ theme }) => theme.colors.black};
  }
  &.bggradientyellow{
    background: linear-gradient(173.11deg, #FABA36 0.66%, #E8435D 116.54%);  }
`;
