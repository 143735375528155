/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Container, Row } from 'react-bootstrap';
import { useState } from 'react';
import * as S from './styles';

import AndreManfrin from '../../../assets/images/home/quem-somo/manfrin.webp';
import FernandaDomingos from '../../../assets/images/home/quem-somo/fernanda.webp';
import GabrielBritzke from '../../../assets/images/home/quem-somo/gabriel.webp';
import GustavoBritzke from '../../../assets/images/home/quem-somo/gustavo.webp';
import JheniferPollet from '../../../assets/images/home/quem-somo/jhenifer.webp';
import PriscylaLippert from '../../../assets/images/home/quem-somo/priscyla.webp';

export default function QuemSoma() {
  const [hoveredDiv, setHoveredDiv] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredDiv(index);
  };

  const handleMouseLeave = () => {
    setHoveredDiv(null);
  };

  const boxes = [
    {
      imagem: AndreManfrin,
      nome: 'André Manfrin',
      cargo: "Sócio-fundador <span class='d-none d-lg-inline'>•</span><br class='d-block d-lg-none' /> Diretor de Negócios",
    },
    {
      imagem: FernandaDomingos,
      nome: 'Fernanda Domingos',
      cargo: "Sócia-fundadora <span class='d-none d-lg-inline'>•</span><br class='d-block d-lg-none' /> Diretora de produto",
    },
    {
      imagem: GabrielBritzke,
      nome: 'Gabriel Britzke',
      cargo: "Sócio-fundador <span class='d-none d-lg-inline'>•</span> <br class='d-block d-lg-none' /> Diretor de criação",
    },
    {
      imagem: GustavoBritzke,
      nome: 'Gustavo Britzke',
      cargo: 'Gerente de projeto',
    },
    {
      imagem: JheniferPollet,
      nome: 'Jhenifer Pollet',
      cargo: 'Consultora de conteúdo',
    },
    {
      imagem: PriscylaLippert,
      nome: 'Priscyla Lippert',
      cargo: 'Produtora Audiovisual',
    },
  ];

  return (
    <>
      <S.QuemSomaContent id="quemsoma">
        <Container className="container-medium">
          <Row>
            <Col className="text-center">
              <h3 className="font-antonio">QUEM SOMA</h3>
            </Col>
          </Row>
        </Container>
      </S.QuemSomaContent>
      <S.Content>
        {boxes.map(({
          imagem, nome, cargo,
        }) => (
          <S.Box
            key={nome}
            className={`${hoveredDiv === nome ? 'hover' : ''}`}
            onMouseEnter={() => handleMouseEnter(nome)}
            onMouseLeave={handleMouseLeave}
          >
            <img src={imagem} alt={nome} />
            <div className="titles">
              <p className="font-antonio">{nome}</p>
              <span dangerouslySetInnerHTML={{ __html: cargo }} />
            </div>
          </S.Box>
        ))}

      </S.Content>
    </>
  );
}
