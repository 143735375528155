import Banner from './Banner';
import Portfolio from './Portfolio';
import QuemSoma from './QuemSoma';
import QuemSomo from './QuemSomo';
import Section2 from './Section2';
import SomoFaz from './SomoFaz';

export default function Home() {
  return (
    <>
      <Banner />
      <Section2 />
      <Portfolio />
      <SomoFaz />
      <QuemSomo />
      <QuemSoma />
    </>
  );
}
