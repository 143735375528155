import styled from 'styled-components';

export const Some = styled.div`
  .crossed-divs-container {
    position: relative;
    height: 600px; /* altura do componente */
    width: 100%;
  }

  .crossed-divs-left,
  .crossed-divs-right {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .crossed-divs-left {
    left: 0px; /* valor negativo para que a div fique fora da tela */
    transition: transform 0.5s ;
    top: 200px;
    span{
      font-weight: 700;
      font-size: 88px;
      line-height: 110%;
      position: absolute;
      width: 600px;
    }
  }

  .crossed-divs-right {
    right: 0px; /* valor negativo para que a div fique fora da tela */
    transition: transform 1s ;
  }
`;

export const SomeMobile = styled.div`
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  img{
    opacity: 0;
    transition: 1s;
  }
  .text{
    position: absolute;
    top: calc(50% - 17.5px);
    left: calc(50% - 152px);
  }
  &.active{
    img{
      opacity: 1;
      transition: 3s;
    }
    .text{
      position: absolute;
      top: 113%;
      left: calc(50% - 152px);
      transition: 2s;
    }
  }
`;
