// Color generation https://smart-swatch.netlify.app

export default {
  colors: {
    background: '#1E1E1E',
    white: '#fff',
    black: '#1E1E1E',
    primary: {
      light: '#f38c9d',
      main: '#E8435D',
      dark: '#a01129',
    },
    yellow: '#FABA36',
    purple: '#6E33FF',
  },
  fonts: {
    1: '10px',
    2: '12px',
    3: '14px',
    4: '16px',
    5: '18px',
    6: '20px',
    7: '24px',
    8: '32px',
    9: '48px',
    10: '56px',
  },
};
