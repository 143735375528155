import styled from 'styled-components';

export const SomoEffectPadding = styled.div`
  padding-top: 220px;
  margin-top: 220px;
  @media (max-width: 700px) {
    padding-top: 0px;
    margin-top: 60px;
  }
  .conteudo {
    opacity: 0;
  }
  .fadein {
    opacity: 1;
    transition: opacity 1s ease;
  }
  .fadeout {
    opacity: 0;
    transition: opacity 0.5s ease;
    @media (max-width: 700px) {
      opacity: 1;
    }
  }
`;

export const SomoCollab = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  #somobig{
      display: inline-block;
  }
  #selo{
    position: absolute;
    top: -80px;
    right: 18%;
    width: 151px;
  }
`;

export const Content = styled.div`
.color-red{
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: bold;
}
p{
  font-weight: 400;
  line-height: 150%;
}
.lf-player-container{
  margin-top: -120px;
  @media (max-width: 700px) {
    margin-top: 0px;
  }
}
`;
