import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ButtonBG from '../../../assets/images/home/portfolio/button-gradient.png';

export const Button = styled.a`
  font-size: ${({ theme }) => theme.fonts[5]};
  border-radius: 25px;
  padding: 6.3px 32px;
  transition: 0.5s;
  &.red{
    color: ${({ theme }) => theme.colors.primary.main};
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    &:hover{
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.yellow{
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.yellow};
    &:hover{
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.black{
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.black};
    &:hover{
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.blackFull{
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};
    border: 2px solid ${({ theme }) => theme.colors.black};
    &:hover{
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.gradient{
    background-image: url(${ButtonBG});
    display: flex;
    height: 64px;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    background-size: contain;
    width: 100%;
    max-width: 547px;
    color: #fff;
    @media (max-width: 700px) {
      height: 49px;
    }
    &:hover{
      color: #fff;
    }
  }
  &.whatsapp{
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primary.main};
    font-family: 'Antonio', sans-serif;
    font-size: 32px;
    line-height: 30px;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 13px 0;
    border-radius: 55px;
    max-width: 511px;
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    &:hover{
    }
  }
  &.apresentacao{
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.purple};
    font-family: 'Antonio', sans-serif;
    font-size: 32px;
    line-height: 30px;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 13px 0;
    border-radius: 55px;
    max-width: 511px;
    border: 2px solid ${({ theme }) => theme.colors.purple};
    &:hover{
    }
  }
  &.apresentacao2{
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.yellow};
    font-family: 'Antonio', sans-serif;
    font-size: 32px;
    line-height: 30px;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 13px 0;
    border-radius: 55px;
    max-width: 511px;
    border: 2px solid ${({ theme }) => theme.colors.yellow};
    &:hover{
      background: ${({ theme }) => theme.colors.primary.main};
      border: 2px solid ${({ theme }) => theme.colors.primary.main};
    }
  }
  &:hover{
    transition: 0.5s;
    text-decoration: none;
    background: ${({ theme }) => theme.colors.yellow};
    border-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.white} !important;
  }
  &.whatsapp-porfilio{
    color: #1E1E1E;
    background: transparent;
    font-family: 'Antonio', sans-serif;
    font-size: 32px;
    line-height: 30px;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 13px 0px;
    border-radius: 55px;
    max-width: 511px;
    border: 4px solid #1E1E1E;
    font-weight: bold;
    &:hover{
      color: #1E1E1E !important;
      background: ${({ theme }) => theme.colors.yellow};
    }
  }
`;
