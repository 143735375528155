import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import somoCircle from '../../../assets/images/somo-circle.svg';

export const Banner = styled.div`
  padding: 25px 0;
  text-align: left;
  position: relative;
  .color-yellow{
    color: yellow;
  }
  p, span{
    font-family: Antonio;
    margin-bottom: 35px;
    line-height: 120%;
  }
  .red{
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .purple{
    color: ${({ theme }) => theme.colors.purple};
  }
  .yellow{
    color: ${({ theme }) => theme.colors.yellow};
  }
  .f-size-62{
    font-size: 62px;
    span{
      font-size: 62px;
    }
  }
  .f-size-88{
    font-size: 88px;
    span{
      font-size: 88px;
    }
  }

  .effec-animation{
    position: absolute;
    right: 5%;
    bottom: 20px;
    z-index: 10;
  }
  @keyframes girar {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 700px) {
    padding: 0;
    .container{
      padding: 0;
    }
    p, span{
    margin-bottom: 24px;
  }
    .f-size-62{
      font-size: 32px;
      span{
        font-size: 32px;
      }
    }
    .f-size-88{
      font-size: 32px;
      span{
        font-size: 32px;
      }
    }
  }
  @media (max-width: 700px) {
    padding-top: 66px;
    .lf-player-container{
      display: none;
    }
    .content-text{
      margin: 0 0 65px 0px !important;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      .align-texts{
        display: flex;
        align-items: start !important;
        justify-content: space-between !important;
        padding-left: 0px;
        flex-direction: column !important;
        min-height: 185px;
        .mb-container{
          margin: 25px 0 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 115px;
          p{
            margin-bottom: 0;
          }
        }
      }
      a{
        min-width:265px;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
      }
    }
  }
`;

export const BannerImage = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 748px;
  @media (max-width: 700px) {
    height: 250px;
    background-image: url(${(props) => props.backgroundImageMobile});
  }
`;

export const Dots = styled.div`
 position: absolute;
 right: 0;
 bottom: 0;
    width: 25px !important;
    right: 0;
    top: 275px;
    .dot{
      background: rgba(255, 255, 255, 0.68);
      display: block !important;
      margin-bottom: 25px !important;
      width: 7.98px !important;
      height: 7.98px !important;
      opacity: 1 !important;
      border: none !important;
      border-radius: 100px !important;
      &.selected{
        position: relative;
        &:after{
          content: "";
          border: 0.79798px solid #FABA36;
          bottom: 20px;
          width: 15.96px;
          height: 15.96px;
          position: absolute;
          border-radius: 100px;
          top: -4px;
          left: -4px;
        }
      }
    }
    @media (max-width: 700px) {
      height: 70px;
      width: 100% !important;
      right: 0;
      top: inherit !important;
      bottom: 0px;
      margin: 0;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
        .dot{
          display: inline-block !important;
          margin: 0 !important;
          padding: 0 !important;
          margin-right: 30px !important;
          width: 15px !important;
          height: 15px !important;
          &.selected{
          position: relative;
          &:after{
            content: "";
            border: 0.79798px solid #FABA36;
            bottom: 20px;
            width: 25px;
            height: 25px;
            position: absolute;
            border-radius: 100px;
            top: -5px;
            left: -5px;
            }
          }
          &:last-child{
            margin-right: 0 !important;
          }
        }
    }
`;
