/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as S from './styles';
import { ReactComponent as WhatsappIcon } from '../../../assets/images/icons/whatsapp.svg';
import { ReactComponent as WhatsappIconPortfolio } from '../../../assets/images/icons/whatsapp-porfolio.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/images/icons/document.svg';

export default function Button({ href, placeholder, variant }) {
  return (
    <S.Button className={variant} href={href} target={variant === 'whatsapp' || variant === 'apresentacao' || variant === 'apresentacao2' ? '_blank' : ''}>
      {variant === 'whatsapp' ? <WhatsappIcon className="mr-3" /> : ''}
      {variant === 'whatsapp-porfilio' ? <WhatsappIconPortfolio className="mr-3" /> : ''}
      {variant === 'apresentacao' || variant === 'apresentacao2' ? <DocumentIcon className="mr-3" /> : ''}
      {placeholder}
    </S.Button>
  );
}
