/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

interface ColorBackgroundTypes {
  colorBackground: string;
  changeColorBackground: (data: string) => void;
}

interface IColorBackgroundProvider {
  children?: React.ReactNode;
}

const ColorBackgroundContext = createContext<ColorBackgroundTypes>({} as ColorBackgroundTypes);

function ColorBackgroundProvider({ children }: IColorBackgroundProvider) {
  const [colorBackground, setColorBackground] = useState<string>('');

  const changeColorBackground = (data: string) => {
    setColorBackground(data);
  };
  return (
    <ColorBackgroundContext.Provider value={{ colorBackground, changeColorBackground }}>
      {children}
    </ColorBackgroundContext.Provider>
  );
}

function useColorBackground() {
  const context = useContext(ColorBackgroundContext);
  if (!context) {
    throw new Error('Erro genérico');
  }
  return context;
}

export { ColorBackgroundProvider, useColorBackground };
