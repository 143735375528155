/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import { Col, Container, Row } from 'react-bootstrap';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import * as S from './styles';

import IconUxui from '../../../assets/images/icons/uxui.svg';
import IconAprentacoes from '../../../assets/images/icons/apresentacoes.svg';
import IconBranding from '../../../assets/images/icons/branding.svg';
import IconConteudo from '../../../assets/images/icons/conteudo.svg';
import IconComunicacoes from '../../../assets/images/icons/comunicacoes.svg';
import IconFotoeVideo from '../../../assets/images/icons/foto-e-video.svg';

export default function SomoFaz() {
  const containerRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current || !elementRef.current) {
        return;
      }

      const elementHeight = elementRef.current.offsetHeight;
      const containerTop = containerRef.current.offsetTop;
      const containerBottom = containerTop + containerRef.current.offsetHeight - elementHeight - 230;
      const { scrollY } = window;

      console.log(elementHeight);

      if (elementHeight > 960) {
        const offset = (elementHeight - 930);
        console.log(offset);
        if (scrollY > containerBottom + offset) {
          elementRef.current.style.position = 'absolute';
          elementRef.current.style.bottom = '0';
          elementRef.current.style.top = null;
        } else if (scrollY > containerTop - offset) {
          elementRef.current.style.position = 'fixed';
          elementRef.current.style.top = '0';
          elementRef.current.style.bottom = 'auto';
        } else {
          elementRef.current.style.position = 'absolute';
          elementRef.current.style.top = `-${offset}px`;
          elementRef.current.style.bottom = null;
        }
      } else if (scrollY > containerBottom) {
        elementRef.current.style.position = 'absolute';
        elementRef.current.style.bottom = '230px';
        elementRef.current.style.top = null;
      } else if (scrollY > containerTop) {
        elementRef.current.style.position = 'fixed';
        elementRef.current.style.top = '0';
        elementRef.current.style.bottom = 'auto';
      } else {
        elementRef.current.style.position = 'absolute';
        elementRef.current.style.top = '0';
        elementRef.current.style.bottom = null;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const divRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
          }
          // else {
          //   entry.target.classList.remove('active');
          // }
        });
      },
      {
        threshold: 0.5, // Define a proporção da div que precisa estar visível para ativar a classe
      },
    );

    divRefs.current.forEach((div) => {
      if (div) {
        observer.observe(div);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <S.SomoFaz id="somofaz" ref={containerRef}>
      <S.BackGroundMobile className="d-block d-lg-none text-center">
        <h4 className="font-antonio">A SOMO FAZ</h4>
        <span>Some e evolua!</span>
      </S.BackGroundMobile>
      <S.BackGround ref={elementRef} className="d-none d-lg-flex">
        <h4 className="font-antonio">A SOMO FAZ</h4>
        <span>Some e evolua!</span>
      </S.BackGround>
      <Container className="container-medium">
        <S.ContentFixed>
          <Row>
            <Col className="d-flex flex-column flex-lg-row justify-content-between position-relative">
              <S.Card className="card1" ref={(el) => (divRefs.current[0] = el)}>
                <img src={IconUxui} alt="1" />
                <span>UX/UI</span>
                <p>
                  Criamos soluções
                  {' '}
                  digitais
                  <br className="d-none d-lg-block" />
                  {' '}
                  que vão da
                  {' '}
                  concepção da
                  <br className="d-none d-lg-block" />
                  {' '}
                  ideia
                  {' '}
                  aos mínimos
                  <br className="d-none d-lg-block" />
                  {' '}
                  detalhes
                  {' '}
                  do design,
                  <br className="d-none d-lg-block" />
                  {' '}
                  somando à
                  {' '}
                  experiência
                  <br className="d-none d-lg-block" />
                  {' '}
                  dos
                  {' '}
                  usuários.
                </p>
              </S.Card>
              <S.Card className="mt-6 mt-lg-8 card2" ref={(el) => (divRefs.current[1] = el)}>
                <img src={IconAprentacoes} alt="1" />
                <span>Apresentações</span>
                <p>
                  Somamos nossas
                  <br className="d-none d-lg-block" />
                  {' '}
                  expertises e levamos
                  {' '}
                  a
                  <br className="d-none d-lg-block" />
                  {' '}
                  arte do PPT a outro
                  {' '}
                  nível,
                  <br className="d-none d-lg-block" />
                  {' '}
                  fortalecendo
                  {' '}
                  marcas e
                  <br className="d-none d-lg-block" />
                  {' '}
                  impactando
                  {' '}
                  pessoas.
                </p>
              </S.Card>
              <div className="plus-white  d-none d-lg-block" />
              <div className="circle d-none d-lg-block" />

            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column flex-lg-row justify-content-center">
              <S.Card className="mt-6 card3" ref={(el) => (divRefs.current[3] = el)}>
                <img src={IconBranding} alt="1" />
                <span>Branding</span>
                <p>
                  Usamos métodos
                  <br className="d-none d-lg-block" />
                  {' '}
                  efetivos e criativos
                  <br className="d-none d-lg-block" />
                  {' '}
                  para somar à história
                  {' '}
                  dos
                  <br className="d-none d-lg-block" />
                  {' '}
                  nossos clientes,
                  {' '}
                  criando
                  <br className="d-none d-lg-block" />
                  {' '}
                  marcas com
                  <br className="d-none d-lg-block" />
                  {' '}
                  personalidade única.
                </p>
              </S.Card>
            </Col>
          </Row>
          <Row className="position-relative">
            <Col className="d-flex flex-column flex-lg-row justify-content-between">
              <S.Card className="mt-6 card4 ml-lg-0" ref={(el) => (divRefs.current[4] = el)}>
                <img src={IconConteudo} alt="1" />
                <span>Conteúdo</span>
                <p>
                  Desenvolvemos
                  <br className="d-none d-lg-block" />
                  {' '}
                  narrativas
                  {' '}
                  <br className="d-block d-lg-none" />
                  que
                  <br className="d-none d-lg-block" />
                  {' '}
                  aproximam as
                  {' '}
                  pessoas
                  <br className="d-block d-lg-none" />
                  <br className="d-none d-lg-block" />
                  {' '}
                  da sua
                  {' '}
                  marca, criando
                  <br className="d-none d-lg-block" />
                  {' '}
                  mais
                  {' '}
                  identificação e
                  <br className="d-none d-lg-block" />
                  {' '}
                  senso
                  {' '}
                  de pertencimento.
                </p>
              </S.Card>
              <S.Card className="mt-6 card5 mt-lg-n7" ref={(el) => (divRefs.current[5] = el)}>
                <img src={IconComunicacoes} alt="1" />
                <span>
                  Comunicação
                  {' '}
                  ON e OFF
                </span>
                <p>
                  Desenvolvemos
                  <br className="d-none d-lg-block" />
                  {' '}
                  comunicação com
                  <br className="d-none d-lg-block" />
                  {' '}
                  propósito, que
                  <br className="d-none d-lg-block" />
                  {' '}
                  fortalecem marcas
                  <br className="d-none d-lg-block" />
                  e geram conexão
                  {' '}
                  com
                  <br className="d-none d-lg-block" />
                  o público,
                  {' '}
                  seja no digital
                  <br className="d-none d-lg-block" />
                  {' '}
                  ou no offline.
                </p>
              </S.Card>
            </Col>
            <div className="plus-red  d-none d-lg-block" />
          </Row>
          <Row>
            <Col className="d-flex flex-column flex-lg-row justify-content-center">
              <S.Card className="last-position-margin mt-6 lg-0 card6  ml-lg-0" ref={(el) => (divRefs.current[6] = el)}>
                <img src={IconFotoeVideo} alt="1" />
                <span>Foto & Vídeo</span>
                <p>
                  Somamos
                  {' '}
                  criatividade e
                  <br className="d-none d-lg-block" />
                  {' '}
                  expertise para criar
                  <br className="d-none d-lg-block" />
                  {' '}
                  conteúdos em foto e
                  <br className="d-none d-lg-block" />
                  {' '}
                  vídeo que, além de
                  <br className="d-none d-lg-block" />
                  {' '}
                  informar, encantam
                  <br className="d-none d-lg-block" />
                  {' '}
                  pessoas.
                </p>
              </S.Card>
            </Col>
          </Row>

        </S.ContentFixed>
      </Container>
    </S.SomoFaz>
  );
}
