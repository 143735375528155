import styled from 'styled-components';

export const Some = styled.div`
  position: relative;
  width: 100%;
  height: 566.37px;
  margin-top: 40px;
  span{
    font-weight: 700;
    font-size: 88px;
    line-height: 110%;
    position: absolute;
  }
  img{
    position: absolute;
  }
`;

export const SliderStyle = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
  .carousel-status{
    display: none;
  }
  .control-dots{
    display: none;
    .logos{
      height: 55px;
      img{
        height: 35px;
      }
    }
  }
`;
