import styled from 'styled-components';

export const Footer = styled.header`
  position: relative;
  padding: 145px 0;
  h4{
    font-size: 56px;
    margin-bottom: 45px;
  }
  .mail{
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  .links{
    gap: 28px;
    display:flex;
    .fill{
      &:hover{
        path{
          transition: 0.4s;
          fill: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
    .stroke{
      &:hover{
        path{
          transition: 0.4s;
          stroke: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
  }
  .animation{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    max-width: 600px;
    @media (max-width: 1700px) {
      right: -100px;
    }
    @media (max-width: 1550px) {
      right: -150px;
    }
    @media (max-width: 1550px) {
      right: -200px;
    }
    @media (max-width: 1300px) {
      right: -300px;
    }
    @media (max-width: 1200px) {
      right: -400px;
    }
    @media (max-width: 700px) {
      width: 90%;
      right: 0;
      top: inherit;
      bottom: -170px;
    }
  }

  @media (max-width: 700px) {
    padding-top: 40px;
    h4{
      font-size: 40px !important;
    }
    h3{
      margin-top: 60px;
      font-size: 32px !important;
      color: #1E1E1E;
      margin-bottom: 25px;
      line-height: 110%;
      strong{
        font-size: 32px;
        color: #1E1E1E;
      }
    }
  }
  &.change-color{
    background: #EEEEEE;
    h4{
      color: #1E1E1E;
    }
    .mail{
        color: #1E1E1E;
        transition: 0.3s;
        text-decoration: underline;
        &:hover{
          color: ${({ theme }) => theme.colors.yellow};
          transition: 0.3s;
        }
      }
      .links{
        .fill{
          svg{
            path{
              fill: #1E1E1E;
              transition: 0.3s;
            }
          }
          &:hover{
            svg{
              path{
              fill: ${({ theme }) => theme.colors.yellow};
              transition: 0.3s;
            }
          }
          }
        }
        .stroke{
          svg{
            path{
              stroke: #1E1E1E;
              transition: 0.3s;
            }
          }
          &:hover{
            svg{
            path{
            stroke: ${({ theme }) => theme.colors.yellow};
            transition: 0.3s;
          }
          }
          }
        }
      }
  }
  #selo{
    position: absolute;
    top: -110px;
    left: 10%;
    width: 140px;
    transform: rotate(-33.66deg);
    @media (max-width: 700px) {
      left: calc(50% - 75.5px);
    }
  }

  &#footerCases{
    position: relative;
    background: #6E33FF;
    padding: 100px 0 0px;
    #selo{
      position: absolute;
      top: -70px;
      left: 10%;
      width: 140px;
      transform: rotate(-33.66deg);
      @media (max-width: 700px) {
        left: calc(50% - 75.5px);
      }
    }
    .animation{
      width: 600px;
      right: -120px;
      top: 30%;
    }
  }
`;

export const Footer2 = styled.div`
  background: #fff;
  p, strong{
    padding: 25px 0;
    color: #353A40;
    font-size: 10px;
    margin-bottom: 0;
  }
  @media (max-width: 700px) {
    p, strong{
      padding:0;
      text-align: center;
    }
    padding: 20px 0;
    }
`;
