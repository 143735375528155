import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import useOnScreen from '../../hooks/useOnScreen';
import './styles';

export default function GalleryItem({ src, width }) {
  const ref = useRef(null);
  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div
      className="gallery-item"
      data-scroll
      ref={ref}
    >
      <div className={cn('gallery-item-image', { reveal })} style={{ width }}>
        {src.includes('webp') ? (
          <div
            className="gallery__item-imginner"
            style={{ backgroundImage: `url(${src})`, width }}
          />
        ) : (
          <Player
            className="animation"
            autoplay
            loop
            src={src}
            style={{ width }}

          />
        )}
      </div>
    </div>
  );
}
