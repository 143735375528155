import styled from 'styled-components';
import Circle from '../../assets/images/home/somofaz/circle.png';

export const Header = styled.header`
  height: 88px;
  color: ${({ theme }) => theme.colors.white};
  a{
    color: ${({ theme }) => theme.colors.white};
    &:hover{
      color: ${({ theme }) => theme.colors.yellow};
      text-decoration: none;
    }
  }
  position: fixed;
  &.simple-menu{
    background-color: #fff;
    color: ${({ theme }) => theme.colors.black};
    transition: 1s;
    position: absolute;
    a{
      color: ${({ theme }) => theme.colors.black};
      &.red{
        color: ${({ theme }) => theme.colors.primary.main};
      }
      &:hover{
        color: ${({ theme }) => theme.colors.yellow};
        text-decoration: none;
      }
    }
    border-bottom: 1px solid #464646;
  }
  .width-ajst{
    min-width: 99px;
  }
  width: 100%;
  z-index:100;
  transition: 1s;
  transition-delay: 0.5s;

  .button-menu{
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: flex-end;
    span{
      background: #E8435D;
      border-radius: 14px;
      width: 35px;
      height: 2px;
      display: inline-block;
      margin-bottom: 8px;
      &:last-child{
        margin-bottom: 0px;
        width: 30px;
      }
    }
  }

  &.fixed-menu{
    transition: 0.2s;
    position: fixed;
    top: 0;
    background-color: rgba(23, 23, 23, 0.6);
    &.simple-menu{
      background-color: #fff;
    }
    backdrop-filter: blur(3px);
    a:hover{
      color: ${({ theme }) => theme.colors.primary.main};
      transition: 0.5s;
      .fill{
        path{
          fill: ${({ theme }) => theme.colors.primary.main};
          transition: 0.5s;
        }
      }
      .stroke{
        path{
          stroke: ${({ theme }) => theme.colors.primary.main};
          transition: 0.5s;
        }
      }
    }

  }

  @media (max-width: 700px) {
    height: 66PX;
    background-color: ${({ theme }) => theme.colors.black};
    position: fixed !important;
    transition: 0.3s;
    &.fixed-menu-mobile{
      background-color: rgba(23, 23, 23, 0.6);
      backdrop-filter: blur(3px);
      transition: 0.5s;
    }
  }
`;

export const LeftMenu = styled.div`
    height: 100%;
    &:last-child{
      margin-right: 0;
    }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 484px;
  width: 484px;
  transition: 2s;
  a{
    margin-right: 24px;
    display: flex;
    align-items: center;
  }
  .links{
    display: none;
    opacity: 0;
    transition: 0.5s;
  }
  &.fixed-menu{
    display: flex;
    opacity: 1;
    transition: 2s;
    max-width: 100%;
    width: 100%;
    align-items: center;
    .links{
      display: flex;
      opacity: 1;
      transition: 0.5s;
      .fill{
        &:hover{
          path{
            transition: 0.4s;
            fill: ${({ theme }) => theme.colors.yellow};
          }
        }
      }
      .stroke{
        &:hover{
          path{
            transition: 0.4s;
            stroke: ${({ theme }) => theme.colors.yellow};
          }
        }
      }
    }
  }
  &.simple-menu{
    .fill{
      path{
        transition: 0.4s;
        fill: ${({ theme }) => theme.colors.black};
      }
    }
    .stroke{
      path{
        transition: 0.4s;
        stroke: ${({ theme }) => theme.colors.black};
      }
    }
  }
  @media (max-width: 700px) {
    width: auto;
    .links{
      display: none;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-right: 54px;
  width: 157px;
  a{
    width: 0;
  }
  .logo-mini{
    opacity: 0
  }
  .logo-big{
    margin-top: 138px;
    /* background-color: red; */
    padding-right: 20px;
    padding-bottom: 20px;
    opacity: 1;
    transition: 500ms;
  }
  &.fixed-menu{
    padding: 0 68px 0 19px;
    border-right: 1px solid #464646;
    width: 88px;
    transition: 2s;
    .logo-big{
      opacity: 0;
      margin-top: -300px;
      transition: 300ms;
    }
    .logo-mini{
      opacity: 1;
      transition: 2s;
      transition-delay: 1s;
    }
  }
  @media (max-width: 700px) {
    padding: 0 20px 0 4px;
    .logo-big{
      display: none;
    }
    border-right: 1px solid #464646;
    width: 60px;
    .logo-mini{
      display: block;
      opacity: 1;
    }
  }
`;

export const BoxMenuMobile = styled.div`
  border-left: 1px solid #464646;
  padding: 0 4px 0 20px;
  height: 66px;
  display: flex;
  align-items: center;
  margin-left: 17px;
`;

export const LanguageBox = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.white};
  font-weight: 300;
  &.black{
    border: 1px solid ${({ theme }) => theme.colors.black};
    color:  ${({ theme }) => theme.colors.black};
  }
  &.simple-menu{
    border: 1px solid ${({ theme }) => theme.colors.black};
    color:  ${({ theme }) => theme.colors.black};
  }
`;

export const BurguerMenu = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 41px !important;
  width: 41px !important;
  right: 18px !important;
  top: 10px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #E8435D;
  width: 4px !important;
  height: 35px !important;
  border-radius: 14px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
  max-width: 680px;
}

/* General sidebar styles */
.bm-menu {
  background: #1E1E1E;
  padding: 0em 0px 0;
  font-size: 1.15em;
  position: relative;
  overflow-y: hidden !important;
  &:after{
    content: "";
    background-image: url(${Circle});
    width: 340px;
    height: 340px;
    z-index: 1;
    display: block;
    animation: girar 20s linear infinite;
    position: absolute;
    bottom: -115px;
    left: -115px;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.bm-burger-button{
  display: none;
}
.bm-menu{
  text-align: right;
  a{
    color: #E8435D;
    transition: 0.3s;
    font-size: 32px;
    &.link{
      padding-bottom: 20px;
      margin-right: 60px;
    }
    &:hover{
      color: #FF9700;
      font-weight: 700;
      text-decoration: none;
      transition: 0.1s;
    }
}
}
/* @media (max-width: 700px) {
    .bm-menu {
      padding: 0em 30px 0 !important;
      font-size: 1.15em;
  }
  .bm-cross-button {
      right: 53px !important;
      top: 51px !important;
  }
} */
`;

export const HeaderMenuMobal = styled.div`
display: flex !important;
height: 64px;
background: #fff;
width: 100%;
align-items: center;
justify-content: space-between;
margin-bottom: 40px;
.logo-container{
  padding: 0 16px;
  border-right: 1px solid #464646;
  height: 100%;
  display: flex;
  align-items: center;
}
.language-container{
  padding: 0 16px;
  border-right: 1px solid #464646;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 64px;
}
`;

export const BgLogo = styled.div`
  padding-left: 23px;
  margin-left: -23px;
  transition: 500ms;
  &.fixed-menu{
    background-color: transparent;
    width: 0;
    transition: 0ms;
  }
`;
