import { Col, Container, Row } from 'react-bootstrap';
import Button from 'components/common/Button';
import { Player } from '@lottiefiles/react-lottie-player';
import SomoBig from 'pages/Cases/SOMOCOLLAB';
import * as S from './styles';
import { ReactComponent as Instagram } from '../../assets/images/icons/instagram.svg';
import { ReactComponent as Behance } from '../../assets/images/icons/behance.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/icons/linkedin.svg';
import Animation from '../../assets/images/footer/animation.json';
import Selo from '../../assets/images/selo.webp';

export default function Footer() {
  const showFooter = window.location.pathname === '/';
  const FooterCases = window.location.pathname === '/cases';

  if (FooterCases) {
    return (
      <>
        <S.Footer id="footerCases">
          <img
            id="selo"
            src={Selo}
            alt="Somo Collab"
          />
          <Container className="container-medium">
            <Row className="d-flex justify-content-center">
              <Col lg={6} className="text-center">
                <h4 className="font-antonio">
                  Fale com a gente
                </h4>
                <Row>
                  <Col lg={6} className="mb-4 mb-lg-0">
                    <Button href="https://wa.me/message/UQRIG6V72FMAC1" placeholder="Whatsapp" variant="whatsapp" />
                  </Col>
                  <Col lg={6}>
                    <Button href="https://somocollab.com/DOCS/Apresentacao-Institucional-Somo-2023.pdf" placeholder="Apresentação" variant="apresentacao2" />
                  </Col>
                </Row>
                {' '}
                <div className="d-flex px-7 justify-content-between mt-4 flex-column flex-lg-row align-items-center">
                  <a href="mailto:contato@somocollab.com" className="mail mb-4 mb-lg-0">contato@somocollab.com</a>
                  <div className="links">
                    <a href="https://www.linkedin.com/company/somocollab/" target="_blank" className="fill" rel="noreferrer noopener">
                      <LinkedIn />
                    </a>
                    <a href="https://www.instagram.com/somocollab_oficial/" target="_blank" className="fill" rel="noreferrer noopener">
                      <Instagram />
                    </a>
                    <a href="https://www.behance.net/somocollab" target="_blank" className="stroke" rel="noreferrer noopener">
                      <Behance />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Player
            className="animation d-none d-lg-block"
            autoplay
            loop
            src={Animation}
          />
          <SomoBig />
        </S.Footer>
        <S.Footer2>
          <Container className="container-medium d-flex justify-content-between flex-column flex-lg-row">
            <p>
              © Copyright 2023 -
              {' '}
              <strong>Todos os direitos reservados SOMO COLLAB LTDA</strong>
            </p>
            <p>
              CNPJ 43.856.295/0001-05 • São Paulo/SP
            </p>
          </Container>
        </S.Footer2>
      </>
    );
  }

  return (
    <>
      <S.Footer id="footer" className={!showFooter ? 'd-block d-lg-none change-color' : ''}>
        <Container className="container-medium">
          <Row>
            <Col lg={8} className="text-center">
              {!showFooter && (
                <img
                  id="selo"
                  src={Selo}
                  alt="Somo Collab"
                />
              )}

              {!showFooter ? (
                <h3 className="font-antonio">
                  Some ideias.
                  {' '}
                  <strong className="font-antonio">
                    Converse
                    <br />
                    {' '}
                    com a Somo
                  </strong>
                </h3>
              ) : (
                <h4 className="font-antonio">
                  Fale com a gente
                </h4>
              )}

              <Row>
                <Col lg={6} className="mb-4 mb-lg-0">
                  <Button href="https://wa.me/message/UQRIG6V72FMAC1" placeholder="Whatsapp" variant="whatsapp" />
                </Col>
                <Col lg={6}>
                  <Button href="https://somocollab.com/DOCS/Apresentacao-Institucional-Somo-2023.pdf" placeholder="Apresentação" variant="apresentacao" />
                </Col>
              </Row>
              <div className="d-flex px-8 mx-4 justify-content-center mt-4 flex-column flex-lg-row align-items-center">
                <a href="mailto:contato@somocollab.com" className="mail mb-4 mb-lg-0 mr-4">contato@somocollab.com</a>
                <div className="links">
                  <a href="https://www.linkedin.com/company/somocollab/" target="_blank" className="fill" rel="noreferrer noopener">
                    <LinkedIn />
                  </a>
                  <a href="https://www.instagram.com/somocollab_oficial/" target="_blank" className="fill" rel="noreferrer noopener">
                    <Instagram />
                  </a>
                  <a href="https://www.behance.net/somocollab" target="_blank" className="stroke" rel="noreferrer noopener">
                    <Behance />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Player
          className="animation"
          autoplay
          loop
          src={Animation}
        />
      </S.Footer>
      <S.Footer2>
        <Container className="container-medium d-flex justify-content-between flex-column flex-lg-row">
          <p>
            © Copyright 2023 -
            {' '}
            <strong>Todos os direitos reservados SOMO COLLAB LTDA</strong>
          </p>
          <p>
            CNPJ 43.856.295/0001-05 • São Paulo/SP
          </p>
        </Container>
      </S.Footer2>
    </>
  );
}
