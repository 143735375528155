/* eslint-disable react/jsx-props-no-spreading */
import Slider from 'react-slick';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as S from './styles';

import image from '../../../assets/images/CasesPage/somobig.svg';

export default function SomoBig() {
  const settings = {
    infinite: true,
    slidesToScroll: 3,
    autoplay: true,
    speed: 20000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    pauseOnHover: true,
    focusOnSelect: true,
    swipeToSlide: true,
    dots: false,
    arrows: false,
    className: 'slider variable-width',
    variableWidth: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="section2">
      <S.SliderStyle>
        <Slider {...settings}>
          <div className="pr-6 my-5 d-none d-lg-flex align-items-center justify-content-center logos">
            <img src={image} alt="A Somo faz" />
          </div>
          <div className="pr-6 my-5 d-none d-lg-flex align-items-center justify-content-center logos">
            <img src={image} alt="A Somo faz" />
          </div>
          <div className="pr-6 my-5 d-flex align-items-center justify-content-center logos">
            <img src={image} alt="A Somo faz" />
          </div>
        </Slider>
      </S.SliderStyle>
    </div>
  );
}
